import { initializeGlobalErrorPopup } from "error-popup"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { prefetchImportantData } from "./bonzai/prefetchImportantData"
import { initializeApi } from "./initialize/initializeApi"
import { initializeAuth } from "./initialize/initializeAuth"
import { initializeI18n } from "./initialize/initializeI18n"
import { initializeLocalise } from "./initialize/initializeLocalise"
import { initializeSentry } from "./initialize/initializeSentry"
import { initializeVuplex } from "./initialize/initializeVuplex"

prefetchImportantData()

initializeApi()
initializeAuth()
initializeGlobalErrorPopup()
initializeI18n()
initializeLocalise()
initializeSentry()
initializeVuplex()

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
