import { auth, v3 } from "src/bonzai/bonzai"
import { PORTAL_LOCALE_KEY } from "./constants"
import { pickLocale } from "./pickLocale"

export const pickLocaleForCurrentCompany = async () => {
  const [companySettings, userSettings, session] = await Promise.all([
    v3.getCurrentCompanySettings.fetchQuery(),
    v3.getUserSettings.fetchQuery(),
    auth.getActiveSession.fetchQuery(),
  ])

  const { portal_supported_locales, default_locale } = companySettings.data
  const allowedLocales = portal_supported_locales.map((locale) => locale.code)
  const serverLocales = userSettings.data.locales

  const portalLocale = serverLocales[PORTAL_LOCALE_KEY]
  if (isLocaleValid(portalLocale, allowedLocales)) {
    return portalLocale
  }

  const sessionLocale = session.pickedLocale
  if (isLocaleValid(sessionLocale, allowedLocales)) {
    return sessionLocale
  }

  const allAppsLocales = Object.values(serverLocales)
  for (const locale of allAppsLocales) {
    if (isLocaleValid(locale, allowedLocales)) {
      return locale
    }
  }

  return pickLocale({
    defaultLocale: default_locale.code,
    browserLocales: window.navigator.languages,
    allowedLocales,
  })
}

const isLocaleValid = (
  locale: string | undefined,
  allowedLocales: string[]
): locale is string => {
  return locale !== undefined && allowedLocales.includes(locale)
}
