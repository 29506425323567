import attensiLogoBlue from "assets/logos/attensiLogoBlue.svg"
import { useWindowWidth } from "hooks"
import { PropsWithChildren, ReactNode } from "react"
import { optimizeImage } from "ui-tools"
import { FiestaLink, FiestaLinkProps } from "utility-components"
import { backgroundImageStyle } from "../../../utils/backgroundImageStyle"
import s from "./AppShell.module.scss"

type AppShellProps = {
  children: ReactNode
  backgroundImage: string | undefined | null
}
export const AppShell = ({ backgroundImage, children }: AppShellProps) => (
  <div
    className={s.app}
    style={backgroundImageStyle(optimizeImage(backgroundImage ?? ""))}
  >
    {children}
  </div>
)

type HeaderProps = {
  navigation: ReactNode
  menu: ReactNode
  companyLogo: string
  avatarLink: FiestaLinkProps
  avatar: string
  userName: string
}
const Header = ({
  navigation,
  menu,
  companyLogo,
  avatarLink,
  avatar,
  userName,
}: HeaderProps) => {
  const { isBigWindow } = useWindowWidth()

  return (
    <header className={s.header}>
      <div className={s.header__container}>
        <FiestaLink to="/">
          <img
            className={s.header__logo}
            src={optimizeImage(companyLogo, { height: 45 })}
            alt="Company logo"
          />
        </FiestaLink>
        {!isBigWindow && (
          <FiestaLink className={s.header__avatarWrapper} {...avatarLink}>
            <img className={s.header__avatar} src={avatar} alt="Company logo" />
            <div className={s.header__userName}>{userName}</div>
          </FiestaLink>
        )}
        {isBigWindow && (
          <div className={s.header__navigation}>{navigation}</div>
        )}
        {menu}
      </div>
    </header>
  )
}

const HeaderSkeleton = () => <div className={s.headerSkeleton} />

const Main = ({ children }: PropsWithChildren) => (
  <main className={s.main}>{children}</main>
)

const Footer = () => (
  <footer className={s.footer}>
    <img className={s.footer__logo} src={attensiLogoBlue} alt="Attensi logo" />
  </footer>
)

const BottomBar = ({ children }: PropsWithChildren) => (
  <div className={s.bottomBar}>{children}</div>
)

const BottomNavigation = ({ children }: PropsWithChildren) => {
  const { isBigWindow } = useWindowWidth()
  if (isBigWindow) return null

  return <div className={s.bottomNavigationBar}>{children}</div>
}

AppShell.Header = Header
AppShell.HeaderSkeleton = HeaderSkeleton
AppShell.Main = Main
AppShell.Footer = Footer
AppShell.BottomBar = BottomBar
AppShell.BottomNavigation = BottomNavigation
