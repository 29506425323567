import { upperFirst } from "lodash-es"

export const getLocaleDisplayName = (locale: string) => {
  const displayNamesIsNotSupported = Intl.DisplayNames === undefined
  if (displayNamesIsNotSupported) return locale

  const displayNames = new Intl.DisplayNames(locale, {
    type: "language",
    languageDisplay: "standard",
  })
  const languageName = displayNames.of(locale)
  return upperFirst(languageName)
}
